var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-4"},[_c('h1',[_vm._v(_vm._s(_vm.pageTitle))]),_c('div',{staticClass:"d-flex flex-column pt-4"},[_c('h2',[_vm._v("Reports")]),_c('div',{staticClass:"flex bg-surface-2"},[_c('div',{staticClass:"row p-2"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v("Event Type")]),_c('v-select',{staticClass:"filter-select",attrs:{"label":'name',"options":_vm.eventTypeOptions,"reduce":(item) => item.value},model:{value:(_vm.queryForm.event_type),callback:function ($$v) {_vm.$set(_vm.queryForm, "event_type", $$v)},expression:"queryForm.event_type"}})],1),_c('div',{staticClass:"col"},[_c('div',[_vm._v("Sub Event Type")]),_c('v-select',{staticClass:"filter-select",attrs:{"label":'name',"options":_vm.subEventTypeOptions,"reduce":(item) => item.value},model:{value:(_vm.queryForm.subevent_type),callback:function ($$v) {_vm.$set(_vm.queryForm, "subevent_type", $$v)},expression:"queryForm.subevent_type"}})],1),_c('div',{staticClass:"col"},[_c('div',[_vm._v("Limit")]),_c('v-select',{staticClass:"filter-select",attrs:{"label":'name',"options":_vm.limitOptions,"reduce":(item) => item.value},on:{"input":_vm.handleLimitOnChange},model:{value:(_vm.queryForm.limit),callback:function ($$v) {_vm.$set(_vm.queryForm, "limit", $$v)},expression:"queryForm.limit"}})],1),(_vm.showCustomLimit)?_c('div',{staticClass:"col"},[_c('div',[_vm._v("Custom limit")]),_c('b-form-input',{attrs:{"placeholder":"Input custom limit","type":"number"},model:{value:(_vm.queryForm.limit),callback:function ($$v) {_vm.$set(_vm.queryForm, "limit", $$v)},expression:"queryForm.limit"}})],1):_vm._e(),_c('div',{staticClass:"col"},[_c('div',[_vm._v("Date Time Range")]),_c('date-range-picker',{ref:"picker",staticClass:"w-100",attrs:{"locale-data":{
                firstDay: 1,
                format: 'dd-mm-yyyy HH:mm'
              },"time-picker-increment":1,"timePicker":true,"time-picker-seconds":true},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("dateTime")(picker.startDate))+" - "+_vm._s(_vm._f("dateTime")(picker.endDate))+" ")]}}]),model:{value:(_vm.queryForm.dateRange),callback:function ($$v) {_vm.$set(_vm.queryForm, "dateRange", $$v)},expression:"queryForm.dateRange"}})],1)]),_c('div',{staticClass:"d-flex justify-content-end p-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"secondary"},on:{"click":_vm.clearFilter}},[_vm._v("Reset ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.getData}},[_vm._v(" Search ")])],1)]),_c('div',{staticClass:"d-flex justify-content-end pt-4 pb-2"},[_c('b-button',{attrs:{"disabled":_vm.result.length === 0},on:{"click":function($event){return _vm.downloadPdf()}}},[_c('i',{staticClass:"fas fa-file-pdf"}),_vm._v(" Export as PDF ")]),_c('download-excel',{staticClass:"ml-1 btn btn-secondary",class:_vm.result.length === 0 ? 'disabled' : null,attrs:{"data":_vm.result,"fields":_vm.jsonFields,"worksheet":"My Worksheet","type":"csv","title":_vm.queryForm.dateRange.startDate +
            ' to ' +
            _vm.queryForm.dateRange.endDate +
            ' System Logs',"name":_vm.queryForm.dateRange.startDate +
            ' to ' +
            _vm.queryForm.dateRange.endDate +
            ' Report.csv',"before-generate":_vm.startDownloadCSV,"before-finish":_vm.finishDownloadCSV}},[_c('i',{staticClass:"fas fa-file-csv"}),_vm._v(" Export as CSV ")])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-table',{attrs:{"id":"temp-target","striped":"","busy":_vm.busy || _vm.busyAcsPerson,"items":_vm.result,"fields":_vm.resultTableFields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection,"filter":_vm.searchTerms,"filter-included-fields":['name'],"small":"","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-white my-2"},[_c('div',{staticClass:"my-5"},[_c('strong',[_vm._v("Please wait. It may take up to a minute...")])]),_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(index)",fn:function(row){return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"cell(subevent_type)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("humanizeText")(row.item.subevent_type))+" ")]}},{key:"cell(created)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("createdAt")(row.item))+" ")]}},{key:"cell(camera_ip)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("cameraIp")(row.item))+" ")]}},{key:"cell(lpr_engine)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("lprEngine")(row.item))+" ")]}},{key:"cell(timestamp)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("timestamp")(row.item))+" ")]}},{key:"cell(license_plate)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("licensePlate")(row.item))+" ")]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }